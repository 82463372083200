<template>
  <section
    class="invoice-preview-wrapper"
    style="position: relative"
  >
    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="invoice === undefined"
    >
      <h4 class="alert-heading">
        Error fetching invoice data
      </h4>
      <div class="alert-body">
        No invoice found with this invoice id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'facture-list' }"
        >
          invoice List
        </b-link>
        for other invoice.
      </div>
    </b-alert>

    <b-row
      v-if="invoice"
      class="invoice-preview"
    >
      <!-- Col: Left (Invoice Container) -->
      <b-col
        cols="12"
        xl="9"
        md="8"
      >
        <b-card
          no-body
          class="invoice-preview-card"
        >
          <!-- Header -->
          <b-card-body class="invoice-padding pb-0">
            <div
              class="d-flex justify-content-center flex-md-row flex-column invoice-spacing mt-0"
            >
              <!-- Header: Left Content -->
              <div style="color: black">
                <div class="logo-wrapper mb-4">
                  <img
                    src="@/assets/images/logo/logo bioherbs-01.png"
                    width="250px"
                    height="auto"
                  >
                  <h3 class="text-primary invoice-logo" />
                </div>
              </div>
              <!-- Header: Right Content -->
              <!-- Header: Right Content -->
            </div>
          </b-card-body>
          <b-card-body>
            <div
              style="color: black"
              class="d-flex flex-row justify-content-between align-items-center"
            >
              <div>
                <h3 style="color: black; font-size: 35px">
                  Bon de livraison
                  <span
                    v-if="invoice.reference < 10"
                    style="color: black ; font-size: 20px;"
                  >
                    N° BL{{ formatDate(invoice.date) }}000{{
                      invoice.reference
                    }}
                  </span>
                  <span
                    v-if="invoice.reference < 100 && invoice.reference > 10"
                    style="color: black ; font-size: 20px;"
                  >
                    N° BL{{ formatDate(invoice.date) }}00{{ invoice.reference }}
                  </span>
                  <span
                    v-if="invoice.reference < 1000 && invoice.reference > 100"
                    style="color: black ; font-size: 20px;"
                  >
                    N° BL{{ formatDate(invoice.date) }}0{{ invoice.reference }}
                  </span>
                  <span
                    v-if="invoice.reference > 1000"
                    style="color: black ;font-size: 20px ;"
                  >
                    N° BL{{ formatDate(invoice.date) }}{{ invoice.reference }}
                  </span>
                </h3>
              </div>
              <div>
                <h3 style="color: black">
                  Date:
                  <span style="color: black"> {{ format(invoice.date) }}</span>
                </h3>
              </div>
            </div>
          </b-card-body>

          <!-- Spacer -->

          <b-card-body class="invoice-padding pb-0">
            <div
              class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
            >
              <!-- Header: Left Content -->

              <!-- Header: Right Content -->
              <div
                v-if="invoice"
                class="mb-2 mt-2"
                style="
                  border-bottom: 2px solid black;
                  border-right: 2px solid black;
                  border-left: 2px solid black;
                  border-radius: 15px;
                  font-size: 22px;
                "
              >
                <b-card-text class="ml-2 mr-2 mt-2 mb-1 font-weight-bold">
                  <span>STE :</span> Bioherbs
                </b-card-text>
                <b-card-text class="ml-2 mr-2 mb-1">
                  <span class="font-weight-bold">Adresse : </span>Dar Chabane ,
                  Nabeul
                </b-card-text>
                <b-card-text class="ml-2 mr-2 mb-1">
                  <span class="font-weight-bold">Tel :</span>
                  +216 24 289 289
                </b-card-text>
                <b-card-text class="ml-2 mr-2 mb-1">
                  <span class="font-weight-bold"> MF :</span>
                  000MB1704494/H
                </b-card-text>
                <!-- <b-card-text class="ml-2 mr-2mb-0">
                  www.bioherbs.tn
                </b-card-text> -->
              </div>
              <div
                v-if="invoice.customer"
                class="mb-2 mt-2"
                style="
                  border-bottom: 2px solid black;
                  border-right: 2px solid black;
                  border-left: 2px solid black;
                  border-radius: 15px;
                  font-size: 22px;
                "
              >
                <b-card-text class="ml-2 mr-2 mt-2 mb-1 font-weight-bold">
                  <span>Client :</span> {{ invoice.customer.company_name }}
                </b-card-text>
                <b-card-text class="ml-2 mr-2 mb-1">
                  <span class="font-weight-bold">Adresse : </span>{{ invoice.customer.address }}
                </b-card-text>
                <b-card-text class="ml-2 mr-2 mb-1">
                  <span class="font-weight-bold">Tel :</span>
                  {{ invoice.customer.phone }} {{ invoice.customer.mobile }}
                </b-card-text>
                <b-card-text class="ml-2 mr-2 mb-1">
                  <span
                    v-if="invoice.customer.type === 'client physique'"
                    class="font-weight-bold"
                  >
                    CIN :</span>
                  <span
                    v-else
                    class="font-weight-bold"
                  > MF :</span>

                  {{ invoice.customer.tax_identification_number }}
                </b-card-text>
                <!-- <b-card-text class="ml-2 mr-2mb-0">
                  www.bioherbs.tn
                </b-card-text> -->
              </div>
            </div>
          </b-card-body>

          <!-- Invoice Description: Table -->
          <b-card-body class="invoice-padding">
            <b-table-lite
              style="font-size: 22px; border: 2px solid black"
              responsive
              :items="p"
              :fields="[
                'désignation',
                'quantité',
                'lot',
                'prix unitaire',
                'remise',
                'montant HT',
              ]"
            >
              <template #cell(taskDescription)="data">
                <b-card-text
                  class="font-weight-bold mb-25"
                  style="font-size: 25px; border: 2px solid black"
                >
                  {{ data.item.taskTitle }}
                </b-card-text>
                <b-card-text class="text-nowrap">
                  {{ data.item.taskDescription }}
                </b-card-text>
              </template>
            </b-table-lite>
          </b-card-body>
          <!-- Invoice Description: Total -->
          <hr class="invoice-spacing">

          <b-card-body
            class="invoice-padding pb-10"
            style="display: flex; justify-content: flex-end"
          >
            <!-- Col: Total -->
            <b-col
              cols="12"
              md="6"
              class="mt-md-6 d-flex justify-content-left"
            >
              <div style="font-size: 22px">
                <table v-if="invoice">
                  <tr>
                    <th>Code</th>
                    <th>Base</th>
                    <th>Taux</th>
                    <th>Montant</th>
                  </tr>
                  <tr>
                    <td>TVA COL</td>

                    <td v-if="invoice">
                      {{ invoice.total_ht }}
                    </td>
                    <td v-else>
                      0
                    </td>
                    <td>19,0</td>
                    <td v-if="invoice">
                      {{ invoice.tax }}
                    </td>
                    <td v-else>
                      0
                    </td>
                  </tr>
                </table>
              </div></b-col>
            <b-col
              cols="12"
              md="6"
              class="mt-md-6 d-flex justify-content-left"
            >
              <div style="font-size: 22px">
                <table v-if="invoice">
                  <tr>
                    <th>Total HT</th>
                    <th>Total Taxes</th>
                    <th>Total TTC</th>
                  </tr>
                  <tr>
                    <td v-if="invoice">
                      {{ invoice.total_ht }}
                    </td>
                    <td v-else>
                      0
                    </td>
                    <td v-if="invoice">
                      {{ invoice.tax }}
                    </td>
                    <td v-else>
                      0
                    </td>
                    <td v-if="invoice">
                      {{ invoice.total_ttc }}
                    </td>
                    <td v-else>
                      0
                    </td>
                  </tr>
                </table>

                <div class="font-weight-bold  mt-1 color:black">
                  {{ lettre }}
                </div>
              </div></b-col>
          </b-card-body>
          <b-card-body
            v-if="invoice.note"
            class="invoice-padding pt-0"
          >
            <div
              class="ml-2"
              style="font-size: 16px"
            >
              <h5 style="color:black;font-size:16px">
                Note
              </h5>
              <hr class="invoice-spacing">
              <div v-html="invoice.note" />
            </div>
            <span />
          </b-card-body>

          <!-- Spacer -->

          <footer
            class="d-flex justify-content-around align-items-center flex-md-row flex-column invoice-spacing mt-0"
          >

            <!-- Header: Left Content -->
            <div class="contact">
              <h3 style="color: white">
                contact@bioherbs.tn
              </h3>
            </div>
            <div
              style="color: black"
              class="d-flex flex-column justify-content-center align-items-center mt-0"
            >
              <div>
                <h3 style="color: black">
                  Dar Chabane , 8000 Nabeul
                </h3>
              </div>
              <div>
                <h3 style="color: black">
                  Tel +216 24 289 289
                </h3>
              </div>
              <div>
                <h3 style="color: black">
                  MF : 000MB1704494/H
                </h3>
              </div>
            </div>
            <div class="contact">
              <h3 style="color: white">
                www.bioherbs.tn
              </h3>
            </div>
            <!-- Header: Right Content -->
            <!-- Header: Right Content -->
          </footer>

        </b-card>
      </b-col>

      <!-- Right Col: Card -->
      <b-col
        cols="12"
        md="4"
        xl="3"
        class="invoice-actions"
      >
        <b-card>
          <!-- Button: Print -->
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            class="mb-75"
            block
            @click="printInvoice"
          >
            Imprimer
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            class="mb-75"
            block
            @click="
              $router.push({
                name: 'bon-livraison-edit',
                params: { id: id },
              })
            "
          >
            Modifier
          </b-button>

          <!-- Button: Edit -->
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { ref } from '@vue/composition-api'
import { NumberToLetter } from 'convertir-nombre-lettre'

import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BTableLite,
  BCardText,
  BButton,
  BAlert,
  BLink,
  VBToggle,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import axios from 'axios'

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableLite,
    BCardText,
    BButton,
    BAlert,
    BLink,
  },
  data() {
    return {
      fields: [
        {
          key: 'customer',
          label: 'Client',
          formatter: customer => `${customer.company_name}`,
        },
        {
          key: 'customer.address',
          label: 'address',
        },
        {
          key: 'customer.phone',
          label: 'téléphone',
        },
        {
          key: 'customer.tax_identification_number',
          label: 'MF',
        },
      ],
      p: [],
      test: '0',
      invoice: [],
      lettre: '',
      sommeRemise: 0,
    }
  },

  created() {
    this.id = this.$route.params.id
    this.getDelivery()
  },
  methods: {
    format(date) {
      if (!date) {
        return null
      }
      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },

    formatDate(date) {
      if (!date) {
        return null
      }
      const [year] = date.split('-')
      return `${year.slice(2, 4)}`
    },
    parseDate(date) {
      if (!date) {
        return null
      }
      const [month, day, year] = date.split('/')
      return `${year.padStart(0, '0')}-${month.padStart(2, '0')}-${day.padStart(
        2,
        '0',
      )}`
    },
    async getDelivery() {
      await axios
        .get(`/deliveries/detail/${this.$route.params.id}/`)
        .then(response => {
          this.invoice = response.data
        })
      if (this.invoice != null) {
        axios
          .post('/deliveries-products/details/', {
            delivery: this.invoice.id,
            products: this.invoice.products,
          })
          .then(res => {
            const result = res.data
            for (let i = 0; i < result.length; i += 1) {
              const qte = result[i].quantity
              const { price } = result[i]
              const { total } = result[i]
              this.addnewitem(
                price,
                qte,
                result[i].product.name,
                total,
                this.invoice.remise,
                result[i].lot,
              )
            }
          })

        const sommeString = this.invoice.total_ttc.toString()
        if (sommeString.includes('.') === false) {
          this.lettre = `${NumberToLetter(this.invoice.total_ttc)} DINARS `
        } else {
          const [dinar, milim] = this.invoice.total_ttc.split('.')
          this.lettre = `${NumberToLetter(dinar)} DINARS , ${NumberToLetter(
            milim,
          )} Millimes`
        }
      }
    },

    addnewitem(price, qte, nom, total, remise, lot) {
      this.p.push({
        désignation: nom,
        'prix unitaire': price,
        quantité: qte,
        lot,
        remise: `${remise} %`,
        'montant HT': total,
      })
    },
  },
  setup() {
    const invoiceData = ref(null)
    const paymentDetails = ref({})

    const printInvoice = () => {
      window.print()
    }

    return {
      invoiceData,
      paymentDetails,
      printInvoice,
    }
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
table,
td,
th {
  padding: 10px;
  text-align: left;
  border: 1px solid black;
}

table {
  width: 100%;
  border-collapse: collapse;
}
.contact {
  background-color: #0b6746;
  padding: 10px;
  border-radius: 20px;
}
</style>

<style lang="scss">
@media print {
  footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: auto; /* Footer height */
  }

  .table thead th {
    white-space: nowrap;
    border-bottom: 2px solid black;
  }

  body {
    background-color: transparent !important;
    page-break-after: always;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }

  .contact {
    background-color: #0b6746;
    padding: 20px;
    border-radius: 25px;
    -webkit-print-color-adjust: exact;
  }
  footer footer-light footer-static {
    display: inline-block;
  }
  footer.footer {
    display: none;
  }

  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
  table,
  td,
  th {
    padding: 10px;
    text-align: left;
    border: 1px solid black;
  }

  table {
    width: 100%;
    border-collapse: collapse;
  }
}
</style>
